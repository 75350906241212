<template>
  <div class="text-center">
    <v-btn class="mt-8 unset-capitalize" color="marca-0-l40" text @click="goBack">
      <mf-icon class="mr-2" color="marca-0-l40" fill size="20">arrow_back</mf-icon>
      Voltar para o login
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BackToLogin',
  methods: {
    goBack() {
      this.$emit('go-back')
    },
  },
}
</script>

<style lang="scss" scoped>
.unset-capitalize {
  text-transform: unset !important;
  letter-spacing: 0.02px !important;
}
</style>
